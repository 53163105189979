import React from "react";
import Artykul from "../components/artykul";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import obrazekArtykulu from "../images/Mobilny-CRM-w-Twojej-firmie.jpg";

export const query = graphql`
  query {
    zdjecie1: file(
      relativePath: { eq: "mobilny-crm-saas.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie2: file(
      relativePath: { eq: "otwarty-komunikator-prosty-crm.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie5: file(
      relativePath: { eq: "system-crm-Baner-testuj.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 770, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
const MobilnyCRM = ({ data }) => {
  return (
    <Artykul
      title="Mobilny CRM w Twojej firmie"
      articleImage={obrazekArtykulu}
      keywords={["mobilny CRM"]}
      articleImageAlt="Mobilny CRM w Twojej firmie"
      metaTitle="Mobilny CRM – Co to jest i jak wykorzystać go w firmie?"
      metaDescription="Mobilny CRM dla małych i średnich firm ✅ Zarządzaj sprzedażą już od 5zł/msc. ✅ Testuj za darmo przez 30 dni!"
    >
<h2>Mobilny CRM</h2>
<p>
Mobilność systemów informatycznych jest w dzisiejszych czasach bardzo ważna dla większości przedsiębiorców. Wśród wielu rozwiązań istnieją takie, które pozwalają na obsługę systemu do zarządzania firmą na urządzeniach mobilnych. Jak to wygląda w przypadku takiego oprogramowania, jak system CRM? Czy zarządzanie relacjami z klientem jest możliwe na tablecie czy telefonie? Tak, ponieważ mobilny CRM działa dzięki wykorzystaniu technologii SaaS.
</p>
<h3>Czym jest SaaS?</h3>
<Img
        style={{
          width: "0 auto",
          margin: "0 auto",
          maxWidth: 300
        }}
        
        title="mobilny crm saas"
        alt="mobilny crm saas"
        fluid={data.zdjecie1.childImageSharp.fluid}

      />

<p>
CRM w SaaS, czy też mobilny CRM, to system dla firmy, który nie wymaga instalowania na urządzeniu, z którego jest obsługiwany. To oznacza, że cały system spoczywa na bezpiecznym serwerze (tzw. CRM w chmurze).
<br>
</br>
<br>
</br>
<cite>SaaS – ang. Software as a Service – czyli oprogramowanie jako usługa. Wiele systemów CRM dla małych firm wykorzystuje ten sposób udostępniania. Ułatwia on wdrożenie CRM, a oprogramowanie biznesowe staje się bardziej dostępne, ponieważ możesz z niego korzystać na każdym urządzeniu z Internetem.</cite>
</p>
<h3>Mobilny CRM w pracy</h3>
<p>
<strong>Najlepiej będzie pokazać to na przykładzie:</strong>
<br>
</br>
<br>
</br>
<cite>Wyobraź sobie, że właśnie masz dzisiaj zaplanowaną ważną rozmowę ze swoim klientem. Po drodze do pracy trafisz na ogromny korek, w którym spędzasz następne dwie godziny. Przychodzisz do biura na chwilę przed planowanym telefonem. Czas na przygotowanie minął, a Ty nie wiesz jeszcze, jak ma wyglądać ta rozmowa.
<br>
</br>
<br>
</br>
<strong>Mając mobilny CRM, w takiej sytuacji możesz sprawdzić historię klienta, sięgając po telefon i odpowiednio zaplanować przebieg rozmowy</strong>.</cite>
<br>
</br>
<br>
</br>
Mobilny CRM dla firmy możesz uruchomić na swoim telefonie czy laptopie, będąc w dowolnym miejscu. Nie musisz nawet mieć pod ręką swojego sprzętu, gdyż wystarczy przeglądarka i Internet. To pozwala mieć kontrolę nad firmą zawsze i wszędzie.
<br>
</br>
<br>
</br>
<strong>Nie wymaga instalowania aplikacji CRM, w związku z tym udostępnia Ci pełny system, a nie okrojoną jego wersję. Na telefonie możesz korzystać z wszystkich funkcji, które są dostępne na komputerze.</strong>
</p>
<h3>Jak działa?</h3>
<p>
<strong>CRM zbiera wszystkie dane o Twojej sprzedaży i porządkuje je, dając Ci dostęp do najważniejszych informacji. 
Porządkuje również Twoich kontrahentów i daje dostęp do historii ich współpracy. 
</strong>
<br>
</br>
<br>
</br>
<cite>
 Mobilny program CRM to system do zarządzania sprzedażą. Opierać się może np. na szansach sprzedaży, bazie kontrahentów i zadaniach handlowców. Wybrane systemy CRM dają także dostęp do analizy działań handlowców i wskaźników związanych z procesem sprzedaży (np. lejek sprzedaży).
</cite>
</p>
<h3>Najważniejsze funkcje CRM:</h3>
<p>
Szanse sprzedaży pozwalają zobrazować proces sprzedaży. Prowadzą handlowca po kolejnych jego etapach i jasno pokazują mu, jakie zadania musi w danym momencie wykonać. Poznasz dzięki nim także prognozowaną wartość sprzedaży i wyciągniesz wnioski z analizy raportów.
<br>
</br>
<br>
</br>
Baza kontrahentów to zbiór informacji o Twoich klientach, ich danych oraz historii waszej współpracy. Pomaga handlowcom dbać o relacje i zapisywać notatki w jednym miejscu. Dzięki temu nawet przy nieobecności czy nagłego odejścia danej osoby, nie tracisz wiedzy o klientach.
<br>
</br>
<br>
</br>
Zadania handlowców tworzysz w interaktywnym kalendarzu, który pomaga planować pracę działu sprzedaży. Każde zadanie może być przypisane do klienta i odpowiednio oznaczone. Dzięki temu zyskasz wiedzę o tym, jakie działania są najskuteczniejsze i który handlowiec pracuje najefektywniej.
</p>
<h3>Mobilność w komunikacji</h3>
<Img
        style={{
          width: "0 auto",
          maxWidth: 300
        }}
        
        title="mobilny crm komunikator"
        alt="mobilny crm komunikator"
        fluid={data.zdjecie2.childImageSharp.fluid}

      />

<p>
<strong>Korzystanie z aplikacji biznesowych wymaga od nas oddzielenia komunikacji firmowej od tej prywatnej. Dlatego warto mieć odpowiednią platformę dla kontaktu ze współpracownikami.
</strong>
<br>
</br>
<br>
</br>
Mobilny CRM posiada wbudowany komunikator, który jest integralną częścią systemu. To oznacza, że wiadomości możesz wysyłać do swoich współpracowników np. z poziomu szansy sprzedaży. Odbiorca zobaczy, w jakiej sprawie do niego piszesz, a cała rozmowa zostanie zapisana w systemie na przyszłość. Dobry system CRM powinien także wspierać dodawanie załączników czy zdjęć i umożliwiać tworzenie czatów grupowych.
</p>
<h3>Bez skomplikowanego wdrożenia</h3>
<p>
Dodatkową zaletą, którą pochwalić się może mobilny CRM jest to, że można go uruchomić w kilka chwil. Odpowiedni dostawca oprogramowania powinien udostępnić darmową wersję próbną systemu, której przekształcenie w kompletny, funkcjonalny system odbędzie się bez problemu. Początek pracy w CRM jest wtedy dużo łatwiejszy, ponieważ nie instalujesz żadnych programów i pracujesz z systemem, jak chcesz.

</p>
<Link to="https://synergiuscrm.pl/synergius-crm-wersja-demo">
        <Img
        style={{
          width: "0 auto",
          margin: "0 auto"
        }}
        
        title="Testuj mobilny CRM"
        alt="Testuj mobilny CRM"
        fluid={data.zdjecie5.childImageSharp.fluid}

      />
      </Link>
    </Artykul>
    
  );
};

export default MobilnyCRM;